@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.header-link{
    @apply text-white font-bold font-outfit text-[12px] uppercase tracking-[1px]  cursor-pointer transition-all duration-300 hover:text-main
}

.Active{
    @apply text-main
}
#Navbar{
    @apply transition-all duration-300
}


/*Card*/

.header {
  text-align: center;
}
.header span {
  padding-right: 5px;
}

.cards-wrapper {
  margin-top: 50px;
}

.card-container {
  perspective: 1200px;
}

.card {
  margin: 0 auto;
  height: 450px;
  width:356px;
  max-widt: 90%;
  position: relative;
  border-radius: 25px;
  transition: all 1s ease;
  transform-style: preserve-3d;
  
  /*animation-duration:2s;
  animation-fill-mode:forwards;*/
}
@media screen and (max-width:460px){
    .card{
        width:280px;
        height:370px;
    }
}
@keyframes aaa {
    0% {
        transform: rotateY(0deg);      
    }
    25% {
        transform: rotateY(20deg);      
    }
    50% {
        transform: rotateY(-180deg);      
    }
    75% {
        transform: rotateY(-160deg);      
    }
    100%{
        transform: rotateY(-180deg);
    }
}
.card:hover {
    /*    animation-name:aaa;*/
  transform: rotateY(-180deg);
}

.card-contents {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}


.card-depth {
  transform: translateZ(100px) scale(0.98);
  perspective: inherit;
}

.card-front {
  transform-style: preserve-3d;
}
/*.card_shadow:hover{
  box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}*/

.card-back {
  transform: rotateY(180deg);
  background-color:#91765a;
  transform-style: preserve-3d;
}
.Test{
  background-image: linear-gradient(135deg, #FD4401 0%, #E44873 100%);
}
.Skill__Trck{
  background-image: linear-gradient(135deg, #FD4401 0%, #E44873 100%);
}
.image-filter{
  filter: brightness( 100% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg );
  transition:all .5s linear;
}
.card_shadow:hover .image-filter{
  filter:none;
  transform:translateY(-10px)
}

/*Card*/


.ReactModal__Overlay {
    z-index:999999999999999999999;
} 
.ReactModal__Overlay--after-open{
    z-index:999999999999999999999;
}
.video-react-controls-enabled ,.video-react-paused ,.video-react-fluid ,.video-react-user-inactive ,.video-react-workinghover ,.video-react{
    padding-top:0 !important;
}
.video-react-controls-enabled ,.video-react-paused ,.video-react-fluid ,.video-react-user-inactive ,.video-react-workinghover ,.video-react{
  padding:0 !important;
}
.video-react-bezel ,.video-react-bezel-animation-alt{
  display:none !important;
}
.video-react-button ,.video-react-big-play-button ,.video-react-big-play-button-left ,.big-play-button-hide{
  display:none !important;

}
.video-react-control-bar ,.video-react-control-bar-auto-hide{
  display:none !important;
  
}
.Dotted-After::after{
    border-bottom:1px dashed rgba(145, 118, 90, 0.3);
}

.Image-Filter{
  filter: invert(48%) sepia(39%) saturate(312%) hue-rotate(350deg) brightness(92%) contrast(93%);
}
.ankap{
  height:30px !important;
  color:red ;
  padding:30px !important;
}